<template>

  <embed 
  fluid
    v-if="pdfBase64 != ''"
    :src="'data:application/pdf;base64,' + pdfBase64"
    width="100%"
    style="height: calc(100vh - 80px);"
  />

</template>

<script>
  //import errorUtils from '@/utils/errorUtils'
  //import formatUtils from '@/utils/formatUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const UserRepository = RepositoryFactory.get('user');

  export default {
    name: 'PrintCoverPage',

    //components: {
    //  courseExplorer: () => import("@/components/CourseExplorer"),
    //  lessonViewer: () => import("@/components/LessonViewer"),
    //},

    data: () => ({
      pdfBase64: ''
    }),

    mounted() {      
      this.loadCoverPage();
    },

    methods: {

      async loadCoverPage() {
        let { data } = await UserRepository.getCoverPagePdf(this.$route.query.userscoreid);
        if (data.success) {
          this.pdfBase64 = data.data.pdfbase64;
        }       
      }

    },

  }
</script>


